import React, { useState } from 'react';
import '../css/Gallary.css';
import { ImageBASE_URL } from '../ApiContext/Config';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const Tile = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [filter, setFilter] = useState('all');

  // Extract images and their categories
  const images = data.flatMap((category) => category.photos.map((photo) => ({
    src: ImageBASE_URL + photo.image,
    title: category.title,
    category: category.title
  })));

  // Filtered images based on the selected category
  const filteredImages = filter === 'all'
    ? images
    : images.filter((image) => image.category === filter);

  const openLightbox = (index) => {
    setIsOpen(true);
    setPhotoIndex(index);
  };

  const closeLightbox = () => {
    setIsOpen(false);
  };

  // Handle filter button click
  const handleFilterClick = (value) => {
    setFilter(value);
  };

  return (
    <div className="container">
      <div className="row">
        <div align="center">
          <button
            className={`btn btn-default filter-button mx-2 ${filter === 'all' ? '' : 'active'}`}
            onClick={() => handleFilterClick('all')}
          >
            All
          </button>

          {data.map((category) => (
            <button
              key={category.id}
              className={`btn btn-default filter-button mx-2 ${filter === category.title ? '' : 'active'}`}
              onClick={() => handleFilterClick(category.title)}
            >
              {category.title}
            </button>
          ))}
        </div>
        <br />

        <div className="row">
          {filteredImages.map((item, index) => (
            <div key={index} className="gallery_product col-lg-4 col-md-4 col-sm-4 col-xs-6">
              <img
                src={item.src}
                alt={item.title}
                className="img-responsive"
                onClick={() => openLightbox(index)}
              />
            </div>
          ))}
        </div>

        {isOpen && (
          <Lightbox
            mainSrc={filteredImages[photoIndex].src}
            nextSrc={filteredImages[(photoIndex + 1) % filteredImages.length].src}
            prevSrc={filteredImages[(photoIndex + filteredImages.length - 1) % filteredImages.length].src}
            onCloseRequest={closeLightbox}
            onMovePrevRequest={() =>
              setPhotoIndex((photoIndex + filteredImages.length - 1) % filteredImages.length)
            }
            onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % filteredImages.length)}
          />
        )}
      </div>
    </div>
  );
};

export default Tile;
