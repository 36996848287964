import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const CompanySlider = () => {
  const settings3 = {


    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
    rtl: false,
    autoplay: true,
    verticalSwiping: true,
    arrows: false,
    autoplaySpeed: 2000,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  const images = [
    { src: "/assets/wp-content/uploads/2022/03/rec-1.jpg", alt: "rec-1" },
    { src: "/assets/wp-content/uploads/2022/03/rec-2.jpg", alt: "rec-2" },
    { src: "/assets/wp-content/uploads/2022/03/rec-3.jpg", alt: "rec-3" },
    { src: "/assets/wp-content/uploads/2022/03/rec-4.jpg", alt: "rec-4" },
    { src: "/assets/wp-content/uploads/2022/03/rec-5.jpg", alt: "rec-5" },
    { src: "/assets/wp-content/uploads/2022/03/rec-6.jpg", alt: "rec-6" },
    { src: "/assets/wp-content/uploads/2022/12/Nestle-min.jpg", alt: "Nestle-min" },
    { src: "/assets/wp-content/uploads/2022/12/Naukri-min.jpg", alt: "Naukri-min" },
    { src: "/assets/wp-content/uploads/2022/12/Khimji-Ramdas-min.jpg", alt: "Khimji Ramdas-min" },
    { src: "/assets/wp-content/uploads/2022/12/Hamdard-min.jpg", alt: "Hamdard-min" },
    { src: "/assets/wp-content/uploads/2022/12/Decathalon-min.jpg", alt: "Decathalon-min" },
    { src: "/assets/wp-content/uploads/2022/12/ICICI-min.jpg", alt: "ICICI-min" },
  ];

  return (
    <div className='w-100' style={{


    }}>

      <Slider {...settings3}>
        {images.map((image, index) => (


          <div key={index} className="swiper-slide ">
            <figure className="swiper-slide-inner m-2">
              <img decoding="async" alt={image.alt} src={image.src} className="swiper-slide-image" />
            </figure>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default CompanySlider;
