import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ApiProvider } from './ApiContext/ApiContext'; // Import the ApiProvider
import Home from './pages/Home';

import ContactUs from './pages/Contact';
import FacultyStaff from './pages/FacultyStaff';
import AboutUs from './pages/About';

import AlumniForm from './pages/Alumni';
import MediaClipList from './pages/MediaClipList';
import MediaClipListDetail from './pages/MediaClipDetail';
import Faq from "./pages/Faq";
import { ChakraProvider } from "@chakra-ui/react";


function App() {
  return (
    <ChakraProvider>
      <ApiProvider>
        {" "}
        {/* Wrap your Router with ApiProvider */}
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />

            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/alumni" element={<AlumniForm />} />
            <Route path="/faq" element={<Faq />} />

            <Route path="/academy/:slug/" element={<AboutUs />} />
            <Route
              path="/academy-detail/:slug/"
              element={<MediaClipListDetail />}
            />
            <Route path="/:slug/" element={<MediaClipList />} />
            <Route path="/faculty-staff" element={<FacultyStaff />} />

            {/* Add routes for other components */}
          </Routes>
        </Router>
      </ApiProvider>
    </ChakraProvider>
  );
}

export default App;
