import {useContext,React} from 'react';
import { ApiContext } from "../ApiContext/ApiContext";
import { ImageBASE_URL } from '../ApiContext/Config';

const styles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000,
    },
    popup: {
      position: 'relative',
      width: '80%',
      maxWidth: '600px',
      borderRadius: '5px',
      overflow: 'hidden',
    },
    closeButton: {
      position: 'absolute',
      top: '10px',
      right: '10px',
      background: 'none',
      border: 'none',
      color: 'red',
      fontSize: '30px',
      cursor: 'pointer',
      zIndex: 2,
    },
    image: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  };
  
  

const PopupMain = ({ show, onClose }) => {
    const { BusinessSetting, Home, Page, loading, error } =
    useContext(ApiContext);

    const popupImage =
    BusinessSetting && BusinessSetting.data
      ?ImageBASE_URL + BusinessSetting.data.popup_image
      : "";

  if (!show) return null;

  return (
    <div style={styles.overlay}>
      <div style={styles.popup}>
      <button onClick={onClose} style={styles.closeButton}>&times;</button>
        {BusinessSetting && BusinessSetting.data && <img src={popupImage} alt="Popup Image" style={styles.image} />}
      </div>
    </div>
  );
};

export default PopupMain;
