import React from 'react';
import { Link } from 'react-router-dom';
import { ImageBASE_URL } from '../ApiContext/Config';
import { useNavigate } from 'react-router-dom';

const AlumaniListCard = ({ data }) => {
    const navigate = useNavigate();

    const handleLink = (slug, pageId, id) => {
        const state = {
            pageId: pageId,
            id: id
        };
        navigate(`/academy-detail/${slug}`, { state });
    };
    return (
        <div
            data-elementor-type="wp-page"
            data-elementor-id="1379"
            className="elementor elementor-1379"
        >
            <section
                className="elementor-section elementor-top-section elementor-element elementor-element-24d2cbb elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                data-id="24d2cbb"
                data-element_type="section"
            >
                <div className="elementor-container elementor-column-gap-default">
                    <div
                        className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-c005170"
                        data-id="c005170"
                        data-element_type="column"
                    >
                        <div className="elementor-widget-wrap elementor-element-populated">
                            <div
                                className="elementor-element elementor-element-312276b elementor-posts--thumbnail-top elementor-grid-3 elementor-grid-tablet-2 elementor-grid-mobile-1 elementor-widget elementor-widget-posts"
                                data-id="312276b"
                                data-element_type="widget"
                                data-settings='{"custom_columns":"3","custom_columns_tablet":"2","custom_columns_mobile":"1","custom_row_gap":{"unit":"px","size":35,"sizes":[]},"custom_row_gap_tablet":{"unit":"px","size":"","sizes":[]},"custom_row_gap_mobile":{"unit":"px","size":"","sizes":[]}}'
                                data-widget_type="posts.custom"
                            >
                                <div className="elementor-widget-container">
                                    <div
                                        className="ecs-posts elementor-posts-container elementor-posts   elementor-grid elementor-posts--skin-custom"
                                        data-settings='{"current_page":1,"max_num_pages":2,"load_method":"numbers_and_prev_next","widget_id":"312276b","post_id":1379,"theme_id":1379,"change_url":false,"reinit_js":false}'
                                    >
                                        {data.map((image, index) => (
                                            <div key={image.id}>
                                            <div
                                              className="card mb-3 img_frame"
                                              style={{ height: "200px" }}
                                              >
                                              <div
                                                className="row g-0"
                                                style={{ height: "200px" }}
                                              >
                                                <div className="col-6">
                                                  <img
                                                    src={ImageBASE_URL + image.photo}
                                                    alt={image.photo}
                                                    className="img-fluid rounded-start"
                                                    style={{
                                                      height: "200px",
                                                      objectFit: "cover",
                                                    }}
                                                  />
                                                </div>
                                                <div
                                                  className="col-6 d-flex justify-content-center align-items-center"
                                                  style={{
                                                    backgroundColor: "#8b0739",
                                                    textAlign: "center",
                                                    height: "200px",
                                                  }}
                                                >
                                                  <div className="card-body d-flex flex-column justify-content-center align-items-center">
                                                    <h5 className="card-title text-center text-white">
                                                      {image.name}
                                                    </h5>
                                                    <p className="card-text text-center text-white">
                                                      {image.current_position}
                                                    </p>
                                                    <img
                                                      src={
                                                        ImageBASE_URL + image.company_logo
                                                      }
                                                      style={{
                                                        height: "80px",
                                                        width: "80px",
                                                      }}
                                                      alt={image.company_logo}
                                                      className="img-fluid rounded-start text-center"
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
      
                                            
                                          </div>
                                        ))}
                                    </div>
                                 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default AlumaniListCard;
