import React from 'react';
import { useNavigate } from 'react-router-dom';

const MyLink = ({ slug, pageId, id, children, className }) => {
  const navigate = useNavigate();

  const handleClick = (e) => {
    e.preventDefault();
    const state = {
      pageId: pageId,
      id: id,
    };
    // Store the state in local storage
    localStorage.setItem('academyState', JSON.stringify(state));
    navigate(`/academy/${slug}`, { state });
  };

  return (
    <a href={`/academy/${slug}`} onClick={handleClick} className={className}>
      {children}
    </a>
  );
};

export default MyLink;
