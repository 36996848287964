import React from 'react';
import { Container, Row, Col, Card, CardBody, CardTitle } from 'reactstrap';
import './CustomCarousel.css';

const YouTubeEmbed = ({ videoLink }) => {
  return (
    <Container className='responsive-height' >
      <Row className="justify-content-center">
        <Col md="12">
          <Card>
            <CardBody>
             
              <div className="custom-video-height embed-responsive embed-responsive-16by9">
                <iframe
                  className="embed-responsive-item"
                  src={videoLink}
                  allowFullScreen
                  title="YouTube video"
                ></iframe>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default YouTubeEmbed;
