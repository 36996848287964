import React from "react";
import Tile from "./Tiles";
import "../css/Gallary.css";

const Gallery = ({ data }) => {
  return (
      <Tile data={data.data} />
  );
};

export default Gallery;
