import React from 'react';
import { useNavigate } from 'react-router-dom';

const MyLink2 = ({ className,children }) => {
  const navigate = useNavigate();

  const handleClick = (e) => {
    e.preventDefault();
   
    navigate(`/faculty-staff`);
  };

  return (
    <a href={`/faculty-staff`} onClick={handleClick} className={className}>
      {children}
    </a>
  );
};

export default MyLink2;
