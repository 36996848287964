import React, { useEffect, useState, useContext } from 'react';
import { Link } from "react-router-dom";
import Footer from "../component/Footer";
import Slider from "react-slick";
import { ApiContext } from "../ApiContext/ApiContext";
import { BASE_URL, ENDPOINTS, ImageBASE_URL } from "../ApiContext/Config";

import { useNavigate } from "react-router-dom";
import {
  CardTitle,
  CardText,
  CardImg,
  Container,
  Row,
  Col,
  Card,
} from "reactstrap";
import CustomCarousel from "../component/HomeCarousl";
import YouTubeEmbed from "../component/Video";
import "../component/CustomCarousel.css";
import Header from "../component/Header";
import CompanySlider from "../component/CompanySlider";
import MyLink from "../component/MyLink";
import MyLink2 from "../component/MyLink2";

const Home = () => {
  
  const navigate = useNavigate();
  const { BusinessSetting, Home, Page, loading, error } =
    useContext(ApiContext);

  const videoLink =
    BusinessSetting && BusinessSetting.data
      ? BusinessSetting.data.about_vido_link
      : "";

  console.log(videoLink);

  const handleLink = (slug, pageId, id) => {
    const state = {
      pageId: pageId,
      id: id,
    };
    navigate(`/academy/${slug}`, { state });
  };



  const handleLink2 = (slug, pageId, id, title) => {
    const state = {
      pageId: pageId,
      id: id,
      title: title,
    };
    navigate(`/${slug}`, { state });
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    rtl: true,
    autoplay: true,
    autoplaySpeed: 2000,
    verticalSwiping: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 768, // Mobile breakpoint
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    afterChange: (current) => {
      if (current + 2 >= Home.data.placement.placements.length) {
        // loadMoreItems();
      }
    },
  };

  const settings2 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    rtl: false,
    autoplay: true,
    verticalSwiping: true,
    arrows: false,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768, // Mobile breakpoint
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    afterChange: (current) => {
      if (current + 2 >= Home.data.placement.placements.length) {
        // loadMoreItems();
      }
    },
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      <div className="home page-template page-template-elementor_header_footer page page-id-89 user-registration-page mega-menu-main-menu elementor-default elementor-template-full-width elementor-kit-5 elementor-page elementor-page-89">
        <div
          data-elementor-type="wp-page"
          data-elementor-id={89}
          className="elementor elementor-89"
        >
          <Header />
         

          <CustomCarousel />

          <section
            className="elementor-section elementor-top-section elementor-element elementor-element-46c45a9 mr-left-300 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-id="46c45a9"
            data-element_type="section"
            data-settings='{"background_background":"gradient"}'
          >
            <div className="elementor-container elementor-column-gap-default">
              <div
                className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-c22341c elementor-hidden-tablet elementor-hidden-mobile"
                data-id="c22341c"
                data-element_type="column"
                data-settings='{"background_background":"classic"}'
              >
                <div className="elementor-widget-wrap elementor-element-populated">
                  <div
                    className="elementor-element elementor-element-19e3222 ann-heading elementor-widget elementor-widget-heading"
                    data-id="19e3222"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="elementor-heading-title elementor-size-default">
                        ANNOUNCEMENT
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-15c0959"
                data-id="15c0959"
                data-element_type="column"
              >
                <div className="elementor-widget-wrap elementor-element-populated">
                  <div
                    className="elementor-element elementor-element-5d3093f elementor-widget elementor-widget-shortcode"
                    data-id="5d3093f"
                    data-element_type="widget"
                    data-widget_type="shortcode.default"
                  >
                    <div className="elementor-widget-container">
                      <marquee className="rotate-text">
                        {" "}
                        <a href="#">{Home.data.announcement}</a>
                      </marquee>
                      <div className="elementor-shortcode" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section
            className="elementor-section elementor-top-section elementor-element elementor-element-c33f765 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
            data-id="c33f765"
            data-element_type="section"
          >
            <div className="elementor-container elementor-column-gap-default">
              <div
                className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-1039f99 video-sec"
                data-id="1039f99"
                data-element_type="column"
              >
                <YouTubeEmbed videoLink={videoLink} />
              </div>
              <div
                className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-1fa282a"
                data-id="1fa282a"
                data-element_type="column"
              >
                <div className="elementor-widget-wrap elementor-element-populated">
                  <p
                    dangerouslySetInnerHTML={{
                      __html: `${Home.data.about_us.short_description}`,
                    }}
                  ></p>
                </div>
              </div>
            </div>
          </section>

          {/* content added by me */}

          <div className="m-3 elementor-container elementor-column-gap-default responsive-padding">
            <div
              className="elementor-element elementor-element-12afb4c elementor-widget elementor-widget-heading"
              data-id="12afb4c"
              data-element_type="widget"
              data-widget_type="heading.default"
            >
              <div className="elementor-widget-container">
                <h3 className="elementor-heading-title elementor-size-default">
                  Why GBAMS
                </h3>
              </div>
            </div>
            <div
              className="elementor-element elementor-element-f3e6034 elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
              data-id="f3e6034"
              data-element_type="widget"
              data-widget_type="divider.default"
            >
              <div className="elementor-widget-container">
                <div className="elementor-divider">
                  <span className="elementor-divider-separator"></span>
                </div>
              </div>
            </div>
          </div>
          <section
            className="elementor-section elementor-top-section elementor-element elementor-element-a61f844 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
            data-id="a61f844"
            data-element_type="section"
            data-settings='{"background_background":"classic"}'
          >
            <div className="elementor-container elementor-column-gap-default">
              <div
                className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-4247ae6"
                data-id="4247ae6"
                data-element_type="column"
              >
                <div className="elementor-widget-wrap elementor-element-populated">
                  <section
                    className="elementor-section elementor-inner-section elementor-element elementor-element-461bd74 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                    data-id="461bd74"
                    data-element_type="section"
                  >
                    <div className="elementor-container elementor-column-gap-default">
                      {Home && Home.data
                        ? Home.data.why_gbams.map((card) => (
                            <div
                              className="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-222156a"
                              data-id="222156a"
                              data-element_type="column"
                              data-settings='{"background_background":"classic"}'
                            >
                              <div className="elementor-widget-wrap elementor-element-populated">
                                <div
                                  className="elementor-element elementor-element-9e3beaa elementor-view-stacked elementor-absolute elementor-shape-circle elementor-widget elementor-widget-icon"
                                  data-id="9e3beaa"
                                  data-element_type="widget"
                                  data-settings='{"_position":"absolute"}'
                                  data-widget_type="icon.default"
                                >
                                  <div className="elementor-widget-container">
                                    <div className="elementor-icon-wrapper ">
                                      <div className="elementor-icon responsive-icon">
                                        {/* <i aria-hidden="true" className="fas fa-users" /> */}

                                        <img
                                          src={`${ImageBASE_URL}${card.icon}`}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="elementor-element elementor-element-ee4a22a elementor-widget elementor-widget-counter"
                                  data-id="ee4a22a"
                                  data-element_type="widget"
                                  data-widget_type="counter.default"
                                >
                                  <div className="elementor-widget-container">
                                    <div className="elementor-counter">
                                      <div className="elementor-counter-number-wrapper">
                                        <span className="elementor-counter-number-prefix" />
                                        <span
                                          className="elementor-counter-number"
                                          data-duration={2000}
                                          data-to-value={2600}
                                          data-from-value={0}
                                        >
                                          {card.counting}
                                        </span>
                                        <span className="elementor-counter-number-suffix">
                                          +
                                        </span>
                                      </div>
                                      <div className="elementor-counter-title">
                                        {card.title}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        : ""}
                    </div>
                  </section>
                  <section
                    className="elementor-section elementor-inner-section elementor-element elementor-element-dc80f69 elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                    data-id="dc80f69"
                    data-element_type="section"
                  >
                    <div className="elementor-container elementor-column-gap-default">
                      {Home && Home.data
                        ? Home.data.why_gbams.map((card) => (
                            <div
                              className="elementor-column elementor-col-25 elementor-inner-column elementor-element elementor-element-f2746e1"
                              data-id="f2746e1"
                              data-element_type="column"
                            >
                              <div className="elementor-widget-wrap elementor-element-populated">
                                <div
                                  className="elementor-element elementor-element-976190c elementor-widget elementor-widget-text-editor"
                                  data-id="976190c"
                                  data-element_type="widget"
                                  data-widget_type="text-editor.default"
                                >
                                  <div className="elementor-widget-container">
                                    {/* <MyLink
                                      slug="mbabba-programme"
                                      pageId="9"
                                      id="13"
                                      className="mega-menu-link text-white"
                                    > */}
                                    <p
                                      className="text-white"
                                      dangerouslySetInnerHTML={{
                                        __html: `${card.description}`,
                                      }}
                                    ></p>
                                    {/* </MyLink> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        : ""}
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </section>

          <section
            className="elementor-section elementor-top-section elementor-element elementor-element-d3a37de elementor-section-full_width elementor-section-height-default elementor-section-height-default"
            data-id="d3a37de"
            data-element_type="section"
            data-settings='{"background_background":"classic"}'
          >
            <div className="elementor-container elementor-column-gap-default">
              <div
                className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-a6692b9"
                data-id="a6692b9"
                data-element_type="column"
              >
                <div
                  className="elementor-widget-wrap elementor-element-populated"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    gap: "20px",
                  }}
                >
                  <div
                    className="elementor-element elementor-element-a7cc562 elementor-position-left elementor-view-default elementor-mobile-position-top elementor-vertical-align-top elementor-widget elementor-widget-icon-box"
                    data-id="a7cc562"
                    data-element_type="widget"
                    data-widget_type="icon-box.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="elementor-icon-box-wrapper">
                        <div className="elementor-icon-box-content">
                          <p
                            className="elementor-icon-box-description"
                            dangerouslySetInnerHTML={{
                              __html: `${
                                Home && Home.data
                                  ? Home.data.placement.long_description
                                  : ""
                              }`,
                            }}
                          ></p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="elementor-element elementor-element-4887dbe elementor-widget elementor-widget-spacer"
                    data-id="4887dbe"
                    data-element_type="widget"
                    data-widget_type="spacer.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="elementor-spacer">
                        <div className="elementor-spacer-inner" />
                      </div>
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-4887dbe elementor-widget elementor-widget-spacer"
                    data-id="4887dbe"
                    data-element_type="widget"
                    data-widget_type="spacer.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="elementor-spacer">
                        <div className="elementor-spacer-inner" />
                      </div>
                    </div>
                  </div>

                  <div
                    className="elementor-element elementor-element-4887dbe elementor-widget elementor-widget-spacer"
                    data-id="4887dbe"
                    data-element_type="widget"
                    data-widget_type="spacer.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="elementor-spacer">
                        <div className="elementor-spacer-inner" />
                      </div>
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-07dee1f elementor-widget elementor-widget-spacer"
                    data-id="07dee1f"
                    data-element_type="widget"
                    data-widget_type="spacer.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="elementor-spacer">
                        <div className="elementor-spacer-inner" />
                        <CompanySlider />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* ?mak dynamic */}

              <div
                className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-e770d28 mt-3"
                data-id="e770d28"
                data-element_type="column"
              >
                <div className="elementor-widget-wrap elementor-element-populated">
                  <div
                    className="elementor-element elementor-element-4950211 no-margin elementor-widget elementor-widget-shortcode"
                    data-id={4950211}
                    data-element_type="widget"
                    data-widget_type="shortcode.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="elementor-shortcode">
                        <div className="placement-image-odd">
                          <div
                            className="container post-listing placement-vertical-odd"
                            dir="ltr"
                          >
                            {Home && Home.data ? (
                              <Slider {...settings2}>
                                {Home.data.placement.placements.map(
                                  (image, index) => (
                                    <div key={image.id}>
                                      <div
                                        className="card mb-3 img_frame"
                                        style={{ height: "200px" }}
                                      >
                                        <div
                                          className="row g-0"
                                          style={{ height: "200px" }}
                                        >
                                          <div className="col-6">
                                            <img
                                              src={ImageBASE_URL + image.image}
                                              alt={image.image}
                                              className="img-fluid rounded-start"
                                              style={{
                                                height: "200px",
                                                objectFit: "cover",
                                              }}
                                            />
                                          </div>
                                          <div
                                            className="col-6 d-flex justify-content-center align-items-center"
                                            style={{
                                              backgroundColor: "#8b0739",
                                              textAlign: "center",
                                              height: "200px",
                                            }}
                                          >
                                            <div className="card-body d-flex flex-column justify-content-center align-items-center">
                                              <h5 className="card-title text-center text-white">
                                                {image.name}
                                              </h5>
                                              <p className="card-text text-center text-white">
                                                {image.designation}
                                              </p>
                                              <img
                                                src={
                                                  ImageBASE_URL + image.company
                                                }
                                                style={{
                                                  height: "80px",
                                                  width: "80px",
                                                }}
                                                alt={image.company}
                                                className="img-fluid rounded-start text-center"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                )}
                              </Slider>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="elementor-element elementor-element-5c7d251 no-margin elementor-widget elementor-widget-shortcode"
                    data-id="5c7d251"
                    data-element_type="widget"
                    data-widget_type="shortcode.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="elementor-shortcode">
                        <div className="placement-image-even">
                          <div
                            className="container post-listing placement-vertical-even"
                            dir="rtl"
                          >
                            {Home && Home.data ? (
                              <Slider {...settings}>
                                {Home.data.placement.placements.map(
                                  (image, index) => (
                                    <div key={image.id}>
                                      <div
                                        className="card mb-3 img_frame"
                                        style={{ height: "200px" }}
                                      >
                                        <div
                                          className="row g-0"
                                          style={{ height: "200px" }}
                                        >
                                          <div
                                            className="col-6 d-flex justify-content-center align-items-center"
                                            style={{
                                              backgroundColor: "#8b0739",
                                              textAlign: "center",
                                              height: "200px",
                                            }}
                                          >
                                            <div className="card-body d-flex flex-column justify-content-center align-items-center">
                                              <h5 className="card-title text-center text-white">
                                                {image.name}
                                              </h5>
                                              <p className="card-text text-center text-white">
                                                {image.designation}
                                              </p>
                                              <img
                                                src={
                                                  ImageBASE_URL + image.company
                                                }
                                                style={{
                                                  height: "80px",
                                                  width: "80px",
                                                }}
                                                alt={image.company}
                                                className="img-fluid rounded-start text-center"
                                              />
                                            </div>
                                          </div>
                                          <div className="col-6">
                                            <img
                                              src={ImageBASE_URL + image.image}
                                              alt={image.image}
                                              className="img-fluid rounded-start"
                                              style={{
                                                height: "200px",
                                                objectFit: "cover",
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                )}
                              </Slider>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div className="m-3 mt-3 elementor-container elementor-column-gap-default responsive-padding">
            <div
              className="elementor-element elementor-element-12afb4c elementor-widget elementor-widget-heading"
              data-id="12afb4c"
              data-element_type="widget"
              data-widget_type="heading.default"
            >
              <div className="elementor-widget-container">
                <h3 className="elementor-heading-title elementor-size-default">
                  Life@GBAMS
                </h3>
              </div>
            </div>
            <div
              className="elementor-element elementor-element-f3e6034 elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
              data-id="f3e6034"
              data-element_type="widget"
              data-widget_type="divider.default"
            >
              <div className="elementor-widget-container">
                <div className="elementor-divider">
                  <span className="elementor-divider-separator"></span>
                </div>
              </div>
            </div>
          </div>

          <section className="clients d-flex justify-content-center mb-4">
            <Container className="d-flex justify-content-center ">
              <Row className="d-flex justify-content-center  mb-4">
                <Col sm="3" md="3" style={{ marginTop: "10px" }}>
                  <Card
                    onClick={() =>
                      handleLink(
                        `${Page.data.page[0].slug}`,
                        `${Page.data.page[0].id}`,
                        "1"
                      )
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      src={`${ImageBASE_URL}${
                        Page && Page.data ? Page.data.page[0].image : ""
                      }`}
                      style={{
                        width: "100%",
                        height: "220px",
                        borderRadius: "5px 5px 0 0",
                      }}
                      alt=""
                    />
                    <div className="card-title m-2 text-center">
                      <h6>{Page && Page.data ? Page.data.page[0].name : ""}</h6>
                    </div>
                  </Card>
                </Col>
                <Col sm="3" md="3" style={{ marginTop: "10px" }}>
                  <Card>
                    <MyLink
                      slug="pre-placement-training-skill-buildingimmersive-experiences"
                      pageId="12"
                      id="38"
                      className="mega-menu-link text-dark"
                    >
                      <img
                        src={`${ImageBASE_URL}${
                          Page && Page.data ? Page.data.page[1].image : ""
                        }`}
                        style={{
                          width: "100%",
                          height: "220px",
                          borderRadius: "5px 5px 0 0",
                        }}
                        alt=""
                      />
                      <div className="card-title m-2 text-center">
                        <h6>
                          {Page && Page.data ? Page.data.page[1].name : ""}
                        </h6>
                      </div>
                    </MyLink>
                  </Card>
                </Col>
                <Col sm="3" md="3" style={{ marginTop: "10px" }}>
                  <Card>
                    <MyLink
                      slug="personality-enrichment-activities"
                      pageId="12"
                      id="36"
                      className="mega-menu-link text-dark"
                    >
                      <img
                        src={`${ImageBASE_URL}${
                          Page && Page.data ? Page.data.page[2].image : ""
                        }`}
                        style={{
                          width: "100%",
                          height: "220px",
                          borderRadius: "5px 5px 0 0",
                        }}
                        alt=""
                      />
                      <div className="card-title m-2 text-center">
                        <h6>
                          {Page && Page.data ? Page.data.page[2].name : ""}
                        </h6>
                      </div>
                    </MyLink>
                  </Card>
                </Col>

                <Col sm="3" md="3" style={{ marginTop: "10px" }}>
                  <Card
                    onClick={() =>
                      handleLink2(
                        `${Page.data.page[12].slug}`,
                        `${Page.data.page[12].id}`,
                        "1",
                        `${Page.data.page[12].name}`
                      )
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      src={`${ImageBASE_URL}${
                        Page && Page.data ? Page.data.page[12].image : ""
                      }`}
                      style={{
                        width: "100%",
                        height: "220px",
                        borderRadius: "5px 5px 0 0",
                      }}
                      alt=""
                    />
                    <div className="card-title m-2 text-center">
                      <h6
                        onClick={() =>
                          handleLink2(
                            `${Page.data.page[12].slug}`,
                            `${Page.data.page[12].id}`,
                            "1",
                            `${Page.data.page[12].name}`
                          )
                        }
                      >
                        {Page && Page.data ? Page.data.page[12].name : ""}
                      </h6>
                    </div>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>

          <div
            className="m-3 elementor-container elementor-column-gap-default responsive-padding"
            responsive-padding
          >
            <div className="row">
              <div className="col-lg-3">
                <div className="elementor-container elementor-column-gap-default">
                  <div
                    className="elementor-element elementor-element-12afb4c elementor-widget elementor-widget-heading"
                    data-id="12afb4c"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >
                    <div className="elementor-widget-container">
                      <h3 className="elementor-heading-title elementor-size-default">
                        GBAMS-Vidushi
                      </h3>
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-f3e6034 elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                    data-id="f3e6034"
                    data-element_type="widget"
                    data-widget_type="divider.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="elementor-divider">
                        <span className="elementor-divider-separator"></span>
                      </div>
                    </div>
                  </div>
                </div>

                <section className="clients d-flex justify-content-center mb-4">
                  <Container className="d-flex justify-content-center ">
                    <Row className="d-flex justify-content-center">
                      <Col sm="12" md="12" style={{ marginTop: "10px" }}>
                        <Card>
                          <Link
                            className="mega-menu-link text-dark"
                            to="https://www.gbamsvidushi.com/"
                            target="_blank"
                          >
                            <img
                              src={`${ImageBASE_URL}${
                                Page && Page.data ? Page.data.page[3].image : ""
                              }`}
                              style={{
                                width: "100%",
                                height: "220px",
                                borderRadius: "5px 5px 0 0",
                              }}
                              alt=""
                            />
                            <div className="card-title m-1 text-center">
                              <h6>
                                {Page && Page.data
                                  ? Page.data.page[3].name
                                  : ""}
                              </h6>
                            </div>
                          </Link>
                        </Card>
                      </Col>
                    </Row>
                  </Container>
                </section>
              </div>
              <div className="col-lg-8">
                <div className="elementor-container elementor-column-gap-default">
                  <div
                    className="elementor-element elementor-element-12afb4c elementor-widget elementor-widget-heading"
                    data-id="12afb4c"
                    data-element_type="widget"
                    data-widget_type="heading.default"
                  >
                    <div className="elementor-widget-container">
                      <h3 className="elementor-heading-title elementor-size-default">
                        GBAMS Times
                      </h3>
                    </div>
                  </div>
                  <div
                    className="elementor-element elementor-element-f3e6034 elementor-widget-divider--view-line elementor-widget elementor-widget-divider"
                    data-id="f3e6034"
                    data-element_type="widget"
                    data-widget_type="divider.default"
                  >
                    <div className="elementor-widget-container">
                      <div className="elementor-divider">
                        <span className="elementor-divider-separator"></span>
                      </div>
                    </div>
                  </div>
                </div>

                <section className="clients d-flex justify-content-center mb-4">
                  <Container className="d-flex justify-content-center ">
                    <Row className="d-flex justify-content-center  mb-4 ">
                      <Col sm="4" md="4" style={{ marginTop: "10px" }}>
                        <Card
                          onClick={() =>
                            handleLink2(
                              `${Page.data.page[4].slug}`,
                              `${Page.data.page[4].id}`,
                              "4",
                              `${Page.data.page[4].name}`
                            )
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <img
                            src={`${ImageBASE_URL}${
                              Page && Page.data ? Page.data.page[4].image : ""
                            }`}
                            style={{
                              width: "100%",
                              height: "220px",
                              borderRadius: "5px 5px 0 0",
                            }}
                            alt=""
                          />
                          <div className="card-title m-2 text-center">
                            <h6
                              onClick={() =>
                                handleLink2(
                                  `${Page.data.page[4].slug}`,
                                  `${Page.data.page[4].id}`,
                                  "1",
                                  `${Page.data.page[4].name}`
                                )
                              }
                            >
                              {Page && Page.data ? Page.data.page[4].name : ""}
                            </h6>
                          </div>
                        </Card>
                      </Col>
                      <Col sm="4" md="4" style={{ marginTop: "10px" }}>
                        <Card
                          onClick={() =>
                            handleLink2(
                              `${Page.data.page[5].slug}`,
                              `${Page.data.page[5].id}`,
                              "1",
                              `${Page.data.page[5].name}`
                            )
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <img
                            src={`${ImageBASE_URL}${
                              Page && Page.data ? Page.data.page[5].image : ""
                            }`}
                            style={{
                              width: "100%",
                              height: "220px",
                              borderRadius: "5px 5px 0 0",
                            }}
                            alt=""
                          />
                          <div className="card-title m-2 text-center">
                            <h6>
                              {Page && Page.data ? Page.data.page[5].name : ""}
                            </h6>
                          </div>
                        </Card>
                      </Col>
                      <Col sm="4" md="4" lg="4" style={{ marginTop: "10px" }}>
                        <Card>
                          <MyLink
                            slug="photo-gallery"
                            pageId="12"
                            id="31"
                            className="mega-menu-link text-dark"
                          >
                            <img
                              src={`${ImageBASE_URL}${
                                Page && Page.data ? Page.data.page[6].image : ""
                              }`}
                              style={{
                                width: "100%",
                                height: "220px",
                                borderRadius: "5px 5px 0 0",
                              }}
                              alt=""
                            />
                            <div className="card-title m-2 text-center">
                              <h6>
                                {Page && Page.data
                                  ? Page.data.page[6].name
                                  : ""}
                              </h6>
                            </div>
                          </MyLink>
                        </Card>
                      </Col>
                    </Row>
                  </Container>
                </section>
              </div>
            </div>
          </div>

          <Footer />
        </div>
      </div>

      {/* fotter */}
    </>
  );
};

export default Home;

