import React, { createContext, useState, useEffect } from 'react';
import { BASE_URL, ENDPOINTS } from './Config'

const ApiContext = createContext();

// Create the provider component
const ApiProvider = ({ children }) => {
  const [BusinessSetting, setBusinessSetting] = useState(null);
  const [Home, setHome] = useState(null);
  const [Page, setPage] = useState(null);
  const [data4, setData4] = useState(null);
  const [Enquiry, setEnquiry] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Function to fetch the data from multiple APIs
    const fetchData = async () => {
      try {
        const [response1, response2, response3, response4, response5] = await Promise.all([
          fetch(`${BASE_URL}${ENDPOINTS.BusinessSetting}`),
          fetch(`${BASE_URL}${ENDPOINTS.Home}`),
          fetch(`${BASE_URL}${ENDPOINTS.Page}`),
          fetch(`${BASE_URL}${ENDPOINTS.PageContent}`),
        //   fetch(`${BASE_URL}${ENDPOINTS.Enquiry}`)
        ]);

        const [result1, result2, result3, result4, result5] = await Promise.all([
          response1.json(),
          response2.json(),
          response3.json(),
          response4.json(),
        //   response5.json()
        ]);

        setBusinessSetting(result1);
        setHome(result2);
        setPage(result3);
        setData4(result4);
        // setEnquiry(result5);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    // const intervalId = setInterval(fetchData, 5000);

    // // Clear interval on component unmount
    // return () => clearInterval(intervalId);
  }, []); 

  return (
    <ApiContext.Provider value={{ BusinessSetting, Home, Page, data4, Enquiry, loading, error }}>
      {children}
    </ApiContext.Provider>
  );
};

export { ApiContext, ApiProvider };
